<template>
    <!-- Section for managing FAQs -->
    <section style="padding-left: 25px; margin-left: 20px; padding-right: 10px; margin-right: 10px;">
      <!-- Add new FAQ button -->
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <v-btn @click="addFaq = !addFaq" class="info ml-6 mr-2 mt-4">
                <v-icon left>{{ icons.mdiDatabasePlusOutline }}</v-icon>
                Ajouter un nouveau FAQ
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
  
      <!-- Add new FAQ form -->
      <v-row v-if="addFaq">
        <v-col cols="12" sm="12" md="12">
          <v-card class="mb-6 ml-6 mr-2 mt-1">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!-- Add new FAQ card content -->
              <v-card-title>Ajouter un nouveau Faq</v-card-title>
              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="faqToAdd.question" label="Question" outlined required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <VueEditor v-model="faqToAdd.response" auto-grow outlined label="Response"></VueEditor>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="faqToAdd.rank" label="Rank" outlined type="number" @input="handleRankInput" required></v-text-field>
                  </v-col>
                </v-row>
  
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <!-- Buttons for adding new FAQ -->
                    <div class="d-flex flex-row-reverse">
                      <v-btn   :disabled="
                      faqToAdd.response.length <= 0 || faqToAdd.question.length <= 0|| faqToAdd.rank <= 0
                      " class="info" @click="validate" :loading="isButtonLoading">
                        {{ isButtonLoading ? "Chargement..." : "Suivant" }}
                      </v-btn>
                      <v-btn color="error darken-1 error" text @click="cancelAdd">Clear</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- Frequently Asked Question list -->
      <v-card class="mb-6 ml-6 mt-6 mr-2">
        <v-card-title>Frequently Asked Question</v-card-title>
        <v-text-field style="padding: 15px" v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details clearable :search="search"></v-text-field>
        <!-- FAQ DataTable -->
        <v-data-table
          :search="search"
          :loading="isLoadingTables"
          loading-text="Loading..."
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 30] }"
          :headers="headers"
          :items="listFaq"
          class="elevation-1"
          item-key="id"
        >
          <!-- Modifier and Supprimer actions -->
          <template v-slot:item.modifier="{ item }">
            <v-icon @click="EditFaq(item)">{{ icons.mdiPencil }}</v-icon>
          </template>
          <template v-slot:item.supprimer="{ item }">
            <v-icon @click="DeleteFaq(item)">{{ icons.mdiDeleteOutline }}</v-icon>
          </template>
        </v-data-table>
      </v-card>
  
      <!-- Dialog for editing FAQ -->
      <v-row justify="center">
        <v-dialog v-model="dialogEdit" max-width="800">
          <!-- Edit FAQ dialog content -->
          <v-card>
            <v-card-title class="text-h6 align-center">Modification FAQ</v-card-title>
            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="faqToEdit.question" label="Question" outlined required></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <VueEditor v-model="faqToEdit.response" auto-grow outlined label="Response"></VueEditor>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="faqToEdit.rank" label="Rank" outlined type="number" @input="handleRankInput" required></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error darken-1 error" text @click="reinitialisationEdit">Annuler</v-btn>
              <v-btn color="info darken-1" text @click="EditStatut">Confirmer</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
  
      <!-- Dialog for confirming edit -->
      <v-row justify="center">
        <v-dialog v-model="dialogConfirmEdit" max-width="600">
          <!-- Confirm Edit dialog content -->
          <v-card>
            <v-card-title class="text-h6 align-center">Êtes-vous sûr de vouloir modifier cette FAQ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error darken-1 error" text @click="cancelEditStatut">Annuler</v-btn>
              <v-btn color="info darken-1" text @click="ConfirmeEdit">Confirmer</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
  
      <!-- Dialog for confirming add -->
      <v-row justify="center">
        <v-dialog v-model="dialogConfirmAdd" max-width="600">
          <!-- Confirm Add dialog content -->
          <v-card>
            <v-card-title class="text-h6 align-center">Êtes-vous sûr de vouloir ajouter cette FAQ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error darken-1 error" text @click="reinitialisationAdd">Annuler</v-btn>
              <v-btn color="info darken-1" text @click="ConfirmeAdd">Confirmer</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
  
      <!-- Dialog for confirming delete -->
      <v-row justify="center">
        <v-dialog v-model="dialogDelete" max-width="600">
          <!-- Confirm Delete dialog content -->
          <v-card>
            <v-card-title class="text-h6 align-center">Êtes-vous sûr de vouloir supprimer cette FAQ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error darken-1 error" text @click="reinitialisationDelete">Annuler</v-btn>
              <v-btn color="info darken-1" text @click="ConfirmeDelete">Confirmer</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </section>
  </template>
  
  <script>
  import axios from "axios";
  import Constant from "@/utils/constants";
  import { component as VueCodeHighlight } from "vue-code-highlight";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
  export default {
    components: { VueEditor, VueCodeHighlight },
    data() {

      return {
        isButtonLoading: false,
        faqToAdd:{
    question:"",
    response:"",
    rank:"",
        },
        addFaq:false,
        dialogConfirmEdit:false,
        dialogConfirmation: false,
        icons: {
          mdiFileEyeOutline: "mdi-file-eye-outline",
          mdiPencil: "mdi-pencil",
          mdiDeleteOutline:"mdi-delete-outline",
          mdiDatabasePlusOutline:"mdi-database-plus-outline"
        },
        search: "",
        isLoadingTables: false,
        listFaq: [],
        Complaint: null,
        faqToDelete:[],
        dialogDelete: false,
        faqToEdit:[],
        dialogEdit:false,
        dialogConfirmAdd:false,
        headers: [
          { text: "Question", value: "question", width: '40%', align: "center"  },
          { text: "Response", value: "response" , width: '40%',align: "center" },
          { text: "Rank", value: "rank" },
          { text: "Modifier", value: "modifier", align: "center" },
          { text: "supprimer", value: "supprimer", align: "center" },
        ],
      };
    },
    methods: {
   
        async ConfirmeAdd(){
            try {
    const response = await axios.post(
      Constant.QORANI + `faq/add`,
      {
        question: this.faqToAdd.question,
        response: this.faqToAdd.response,
        rank: this.faqToAdd.rank,
      }
    );

    this.reinitialisationAdd();
    window.location.reload();
  } catch (error) {
    console.error("Error editing FAQ:", error);
  
  }
        },
        async reinitialisationAdd(){
            this.dialogConfirmAdd = false;
        },
        async validate(){
            this.dialogConfirmAdd = true;
        },
      async reinitialisationDelete() {
        this.faqToDelete = [];
        this.dialogDelete = false;
      },
      async  cancelAdd(){
    this.addFaq = false;
    this.faqToAdd.question ="";
    this.faqToAdd.response="";
    this.faqToAdd.rank="";
        },
      async EditFaq(iteam) {
        this.faqToEdit = iteam;
        this.dialogEdit = true;
      },
      async EditStatut(){
        this.dialogConfirmEdit = true;
      },
      async cancelEditStatut(){
        this.dialogConfirmEdit = false;
      },
      async reinitialisationEdit(){
        this.faqToEdit = [];
        this.dialogEdit = false;
      },
      async ConfirmeEdit() {
  try {
    const response = await axios.post(
      Constant.QORANI + `faq/edit/${this.faqToEdit.id}`,
      {
        question: this.faqToEdit.question,
        response: this.faqToEdit.response,
        rank: this.faqToEdit.rank,
      }
    );

    this.reinitialisationDelete();
    window.location.reload();
  } catch (error) {
    console.error("Error editing FAQ:", error);
  }
},
      async DeleteFaq(iteam) {
        this.faqToDelete =iteam
        this.dialogDelete= true
      },
      async ConfirmeDelete(){
       
  try {
    const response = await axios.get(`${Constant.QORANI}faq/delete/${this.faqToDelete.id}`);
    console.log(response.data); 
    this.dialogDelete= false;
    window.location.reload();
  } catch (error) {
    console.error("Error deleting FAQ:", error);
    throw error;
  }
      },
      async getAllFaq() {
  try {
    const response = await axios.get(Constant.QORANI + "faq/getall");
    const faqResponse = response.data;
    const sortedFaq = faqResponse.sort((a, b) => a.rank - b.rank);
    return sortedFaq;
  } catch (error) {
    console.error("Error fetching FAQs:", error);
    throw error;
  }
},
    },
    async created() {
      this.isLoadingTables = true;
      this.listFaq = await this.getAllFaq();
      this.isLoadingTables = false;
    },
  };
  </script>
  <style lang="css">
  @import "~vue2-editor/dist/vue2-editor.css";
  
  /* Import the Quill styles you want */
  @import "~quill/dist/quill.core.css";
  @import "~quill/dist/quill.bubble.css";
  @import "~quill/dist/quill.snow.css";
  </style>
  